console.log('🥑 %cMade by Monk', 'background: #616a2e; color: #f4e9e2; padding: 5px 17px; border-radius: 3px;');
console.log(' %chttps://monk.com.au ', 'padding: 5px 13px;');

import './skip-link-focus-fix';
//import './smoothscroll';
import './navigation';
import './scrollyclasses';
import './viewport-height';
import './fw-video';
import './accordions';
import './fancybox';
import './gsapanims';

jQuery(function ($) {

	//page
	var $hamburger = $(".hamburger"),
		$site = $("body"),
		$menu = $(".main-navigation"),
		$menuitems = $(".menu-item"),
		$screenOverlay = $(".screen-overlay"),
		$tabs = $(".is-style-tabbed .switch .wp-block-button__link"),
		$modalLink = $(".is-style-modal .wp-block-button__link, .is-style-listen .wp-block-button__link"),
		$share = $("#secondary .share a");

		$.fancybox.defaults.hash = false;
		

		//$(".home a.trigger").fancybox().trigger('click');

		if ((document.cookie.indexOf("trigger") === -1) && document.getElementById("trigger")){
			$.fancybox.open({
				src  : '#trigger',
				type : 'inline'
			});
		}

		$(".site-notice .close").on("click", function () {
			$(".site-notice").hide();
			document.cookie = "notice=hide";
		});

		$(".proceed").on("click", function () {
			$.fancybox.close();
			document.cookie = "trigger=done";
			$("body").addClass("emergency");
		});

		$(".secondary .emergency p").on("click", function (event) {
			event.preventDefault();
			$("body").removeClass("emergency");
			$("body").addClass("share");
		});

		$(".secondary .share p").on("click", function (event) {
			event.preventDefault();
			$("body").removeClass("share");
			$("#share").hide();
		});



	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Mobile Menu
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/
	$hamburger.on("click", function () {
		$hamburger.toggleClass("is-active");
		$site.toggleClass("menu-open");
		// if ( $('body').hasClass('menu-open') ) {
		// 	$('#mobile-menu .label').text('Close')
		// } else {
		// 	$('#mobile-menu .label').text('Menu')
		// }
	});


	$tabs.on("click", function (event) {
		event.preventDefault();
		var href = $(this).attr('href');
		$(".is-style-tabbed .wp-block-button").removeClass("active");
		$(this).parent().addClass("active");
		//alert("this works"+href);
		$(".is-style-tab").css("height",0);
		$(href).css("height","auto");
		
	});

	$('#jump_menu').change( function() {
		window.location = $(this).find("option:selected").val();
	});


	$modalLink.on("click", function (event) {
		var href = $(this).attr('href');
		$.fancybox.open([{ src: href, type: "inline" }]);
		//alert("this works"+href);
	});

	$share.on("click", function (event) {
		event.preventDefault();
		$("#share").fadeIn();
	});

	$("#share .close").on("click", function (event) {
		event.preventDefault();
		$("#share").hide();
	});


	// $(".fancybox-close-small").on("click", function (event) {
	// 	event.preventDefault();
	// 	$('body').delay(1000).scrollTop(0);
	// 	//alert ("you did it");
		
	// });
	


	var classes = ["one", "two", "three","four","five"];

    $("h1 em, h1 strong, h2 em, h2 strong").each(function(){
        $(this).addClass(classes[~~(Math.random()*classes.length)]);
    });

	//close menu with an outside click (basically anywhere on .site-content)
	function closeMenu() {
		$site.removeClass("menu-open");
		$menu.removeClass("toggled");
		$menuitems.removeClass('toggled-on');
		$hamburger.removeClass("is-active");
	}

	$screenOverlay.on('click', closeMenu);

	$(document).on('keydown', function (e) {
		if (e.which == 27) {
			closeMenu();
		}
	});

	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Animate elements in on scroll
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/
	// $('.animate').on('inview', function (event, isInView) {
	// 	if (isInView) {
	// 		$(this).addClass('fadein');
	// 	}
	// });
	// AOS.init();


	function copyToClipboard(text) {
		var inputc = document.body.appendChild(document.createElement("input"));
		inputc.value = window.location.href;
		inputc.select();
		document.execCommand('copy');
		inputc.parentNode.removeChild(inputc);
		alert("Page URL Copied to Clipboard");
	}

	$('.share.copy').click(function () { copyToClipboard(); });


});

(function($){

	"use strict";

	// Yes / No
	$("#wthf-yes-no span").click(function(){

		// Getting value
		var value   = parseInt($(this).attr("data-value"));
		var postID  = $("#was-this-helpful").attr("data-post-id");
                var cookie  = "helpful_id_" + postID;
                
                resetCookie(cookie);

		// Send Ajax
		$.post(ajaxurl, {action: "wthf_ajax", id: postID, val: value, nonce: nonce_wthf}).done(function(data){
                    setCookie(cookie, "1");
		});

		// Disable and show a thank message
		setTimeout(function(){
                    $("#was-this-helpful").addClass("wthf-disabled");
		}, 20);

	});
        
        
        function resetCookie(name) {
            
            document.cookie = name +'=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            
        }


	// Set Cookie
	function setCookie(name, value) {
		var expires = "";
		var date = new Date();
		date.setTime(date.getTime() + (365*24*60*60*1000));
		expires = "; expires=" + date.toUTCString();
		document.cookie = name + "=" + (value || "")  + expires + "; path=/";
	}


	// Get Cookie
	function getCookie(name) {
	    var nameEQ = name + "=";
	    var ca = document.cookie.split(';');
	    for(var i=0;i < ca.length;i++) {
	        var c = ca[i];
	        while (c.charAt(0)==' ') c = c.substring(1,c.length);
	        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
	    }
	    return null;
	}
	
})(jQuery);




import './sliders';